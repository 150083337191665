<template>
    <Hero v-editable="blok" :data="hero" />
</template>

<script setup lang="ts">
import Hero from '~/components/page-building/Hero.vue';
import HeroType from '~/types/HeroType';
import ImageType from '~/types/ImageType';
import ButtonType from '~/types/ButtonType';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const heroButtons = props.blok.buttons
    ? props.blok.buttons.map((button: any) => {
          return {
              label: button.label,
              style: button.style,
              url: button.link.cached_url,
              vEditable: button,
          } as ButtonType;
      })
    : [];

const hero = computed<HeroType>(() => {
    return {
        image: {
            title: props.blok.image.title,
            alt: props.blok.image.alt,
            src: props.blok.image.filename,
        } as ImageType,
        title: props.blok.title,
        titleColor: props.blok.title_color,
        primaryContent: richTextToHtml(props.blok.primary_content) as string,
        secondaryContent: richTextToHtml(
            props.blok.secondary_content,
        ) as string,
        backgroundColor: props.blok.background_color,
        buttons: heroButtons,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        vEditable: props.blok,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
    };
});
</script>
